import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const ForwarderUserProductPermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.PRODUCT]: ForwarderUserProductPermissionName,
  [ObjectType.BULK_PRODUCT_UPLOAD_TEMPLATE_DOCUMENT_GENERATION_REQUEST]:
    ForwarderUserProductPermissionName,
  [ObjectType.IMPORT_PRODUCTS_FROM_BULK_UPLOAD_REQUEST]:
    ForwarderUserProductPermissionName,
  [ObjectType.IMPORT_PRODUCTS_FROM_CUSTOMS_ENTRIES_REQUEST]:
    ForwarderUserProductPermissionName,
};
