import React from 'react';
import { Input, Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { capitalCase } from 'change-case';
import { timeout } from '@xbcb/js-utils';
import {
  Select,
  CountrySelect,
  FormItem,
  SimpleSelect,
} from '@xbcb/form-item-components';
import {
  selectFilter,
  formatString,
  show,
  maxLength,
  shouldUpdate,
  safeGetMessage,
} from '@xbcb/ui-utils';
import { conformToMask } from 'text-mask-core';
import { StyledLocationFormDiv, StyledInputGroup } from './styles';
import {
  CssSize,
  NamePath,
  DataCyPrefix,
  DataCySuffix,
  createDataCyValue,
} from '@xbcb/ui-types';
import { UsCbp5106AddressTypeCode } from '@xbcb/party-types';
import { omit } from 'lodash';
import { PartyRecordType } from '@xbcb/shared-types';
import { useBundle } from '@amzn/react-arb-tools';
import AddressForm from '../AddressForm';

type LocationFormProps = {
  usOnly?: boolean;
  foreignOnly?: boolean;
  ior?: boolean;
  zipCodes: any;
  fullNamePath: NamePath;
  localNamePath?: NamePath;
  readOnly?: boolean;
  countryCodes: any;
  disabled?: boolean;
  allRequired?: boolean;
  countryRequired?: boolean;
  onCountrySelect?: any;
  showType?: boolean;
  spaceBottom?: boolean;
  showWcoFormat?: boolean;
  form: FormInstance;
  recordType?: PartyRecordType;
  dataCySuffix?: DataCySuffix;
};

export const stateSelect = ({
  disabled,
  form,
  countryCodeFullNamePath,
  countryCodes,
  notFoundContent,
}: {
  disabled?: boolean;
  form: FormInstance;
  countryCodeFullNamePath: NamePath;
  countryCodes: any;
  notFoundContent: any;
}) => {
  const currentCountryCode = form.getFieldValue(countryCodeFullNamePath);
  const stateOptions = currentCountryCode
    ? Object.entries(countryCodes[currentCountryCode].subdivision)
        .sort(([code0, data0]: [string, any], [code1, data1]: [string, any]) =>
          data0.name.localeCompare(data1.name),
        )
        .map(([code, data]: [string, any]) => (
          <Select.Option key={code} value={code}>
            {data.name}
          </Select.Option>
        ))
    : [];
  return (
    <Select
      disabled={disabled}
      showSearch
      allowClear
      filterOption={selectFilter}
      notFoundContent={notFoundContent}
    >
      {stateOptions}
    </Select>
  );
};

const LocationForm: React.FC<LocationFormProps> = ({
  recordType,
  usOnly,
  foreignOnly,
  ior,
  zipCodes,
  fullNamePath,
  localNamePath,
  readOnly,
  countryCodes,
  disabled,
  allRequired,
  countryRequired,
  onCountrySelect,
  showType,
  spaceBottom = true,
  showWcoFormat = false,
  form,
  dataCySuffix,
}) => {
  // TODO there may be a usecase for the old, commented out, version below, Ed will explore
  // const getKey = (key: string) =>
  //   prefix
  //     ? `${prefix.replace(/_supplierId/, "")}${prefixSeparator}${key}`
  //     : key;
  const [sharedBundle] = useBundle('shared');
  const [locationFormBundle] = useBundle(
    'components.formComponents.LocationForm',
  );
  const getFullNamePath = (field: string) => [...fullNamePath, field];
  const getLocalNamePath = (field: string) => [
    ...(localNamePath || fullNamePath),
    field,
  ];

  const physicalAddressNamePathSegment = 'physical';
  const isPhysical = (localNamePath || fullNamePath).includes(
    physicalAddressNamePathSegment,
  );
  const usCountryCode = 'US';
  const chinaCountryCode = 'CN';
  const addressFullNamePath = getFullNamePath('address');
  const address2FullNamePath = getFullNamePath('address2');
  const countryCodeFullNamePath = getFullNamePath('countryCode');
  const stateCodeFullNamePath = getFullNamePath('stateCode');
  const postalCodeFullNamePath = getFullNamePath('postalCode');
  const cityFullNamePath = getFullNamePath('city');
  const addressLocalNamePath = getLocalNamePath('address');
  const address2LocalNamePath = getLocalNamePath('address2');
  const countryCodeLocalNamePath = getLocalNamePath('countryCode');
  const stateCodeLocalNamePath = getLocalNamePath('stateCode');
  const postalCodeLocalNamePath = getLocalNamePath('postalCode');
  const cityLocalNamePath = getLocalNamePath('city');
  const pathToAddressTypeOnFullNamePath = fullNamePath.includes(
    physicalAddressNamePathSegment,
  )
    ? 'cbpPhysicalAddressType'
    : 'cbpMailingAddressType';
  const pathToAddressTypeOnLocalNamePath = isPhysical
    ? 'cbpPhysicalAddressType'
    : 'cbpMailingAddressType';
  const addressTypeCodeFullNamePath = [pathToAddressTypeOnFullNamePath, 'code'];
  const addressTypeExplanationFullNamePath = [
    pathToAddressTypeOnFullNamePath,
    'explanation',
  ];
  const addressTypeCodeLocalNamePath = [
    pathToAddressTypeOnLocalNamePath,
    'code',
  ];
  const addressTypeExplanationLocalNamePath = [
    pathToAddressTypeOnLocalNamePath,
    'explanation',
  ];
  const isUs = () =>
    form.getFieldValue(countryCodeFullNamePath) === usCountryCode;

  const handleCountryChange = async (value: string) => {
    const clear = [
      {
        name: stateCodeFullNamePath,
        value: undefined,
        errors: allRequired ? [] : undefined,
      },
      { name: postalCodeFullNamePath, value: undefined },
      {
        name: cityFullNamePath,
        value: undefined,
        errors: allRequired ? [] : undefined,
      },
    ];
    if ((isUs() || value === usCountryCode) && ior) {
      clear.push({
        name: ['iorNumber'],
        value: undefined,
        errors: allRequired ? [] : undefined,
      });
    }

    form.setFields(clear);
    await timeout(0);
    form.validateFields([postalCodeFullNamePath]);
  };

  const handlePostalChange = (e?: any) => {
    if (!e || !e.target) {
      return e;
    }
    const value = e.target.value;

    if (!isUs()) return value;

    const conformedValue = conformToMask(
      value,
      [/\d/, /\d/, /\d/, /\d/, /\d/],
      {
        guide: false,
      },
    );

    const zip = zipCodes[conformedValue.conformedValue];
    if (zip) {
      form.setFields([
        { name: cityFullNamePath, value: zip.city },
        { name: stateCodeFullNamePath, value: zip.state },
      ]);
      form.validateFields();
    } else {
      form.setFields([
        {
          name: cityFullNamePath,
          value: undefined,
          errors: allRequired ? [] : undefined,
        },
        {
          name: stateCodeFullNamePath,
          value: undefined,
          errors: allRequired ? [] : undefined,
        },
      ]);
      form.validateFields();
    }

    return conformedValue.conformedValue;
  };

  const showForm = (field: NamePath) => show({ field, readOnly, form });

  return (
    (showWcoFormat && (
      <AddressForm
        form={form}
        fullNamePath={fullNamePath}
        readOnly
        disabled
        dataCySuffix={dataCySuffix}
      />
    )) || (
      <Form.Item shouldUpdate={shouldUpdate([countryCodeFullNamePath])} noStyle>
        {() => {
          let currentCountryCode = form.getFieldValue(countryCodeFullNamePath);
          if (!currentCountryCode) currentCountryCode = usCountryCode;

          return (
            <StyledLocationFormDiv>
              {showForm(addressFullNamePath) && (
                <FormItem
                  label={safeGetMessage(locationFormBundle, 'address_line_one')}
                  name={addressLocalNamePath}
                  rules={[{ required: allRequired, message: ' ' }]}
                  $itemSize={CssSize.MEDIUM}
                  $readOnly={readOnly}
                  debounce
                  data-cy={createDataCyValue(
                    isPhysical
                      ? DataCyPrefix.PHYSICAL_ADDRESS
                      : DataCyPrefix.MAILING_ADDRESS,
                    dataCySuffix,
                  )}
                >
                  <Input
                    disabled={disabled}
                    onBlur={() => formatString(form, addressFullNamePath)}
                  />
                </FormItem>
              )}
              {showForm(address2FullNamePath) && (
                <FormItem
                  label={safeGetMessage(locationFormBundle, 'address_line_two')}
                  name={address2LocalNamePath}
                  $itemSize={CssSize.MEDIUM}
                  $readOnly={readOnly}
                  debounce
                >
                  <Input
                    disabled={disabled}
                    onBlur={() => formatString(form, address2FullNamePath)}
                  />
                </FormItem>
              )}
              <Input.Group>
                {showForm(countryCodeFullNamePath) && (
                  <CountrySelect
                    disabled={disabled}
                    required={allRequired || countryRequired}
                    localNamePath={countryCodeLocalNamePath}
                    foreignOnly={foreignOnly}
                    label={safeGetMessage(locationFormBundle, 'country')}
                    onChange={handleCountryChange}
                    onSelect={onCountrySelect}
                    readOnly={readOnly}
                    $inline
                    usOnly={usOnly}
                    dataCySuffix={dataCySuffix}
                  />
                )}
                {showForm(postalCodeFullNamePath) && (
                  <FormItem
                    label={safeGetMessage(locationFormBundle, 'postal_code')}
                    $inline
                    $itemSize={CssSize.SHORT}
                    name={postalCodeLocalNamePath}
                    rules={[
                      {
                        required:
                          (allRequired &&
                            [usCountryCode, 'CA'].includes(
                              currentCountryCode,
                            )) ||
                          (recordType === PartyRecordType.SUPPLIER &&
                            currentCountryCode === chinaCountryCode),
                        message: ' ',
                      },
                    ]}
                    getValueFromEvent={handlePostalChange}
                    $readOnly={readOnly}
                    data-cy={createDataCyValue(
                      isPhysical
                        ? DataCyPrefix.PHYSICAL_POSTAL_CODE
                        : DataCyPrefix.MAILING_POSTAL_CODE,
                      dataCySuffix,
                    )}
                  >
                    <Input disabled={disabled} />
                  </FormItem>
                )}
              </Input.Group>
              <StyledInputGroup $removeSpaceBottom={!spaceBottom && !showType}>
                {showForm(cityFullNamePath) && (
                  <FormItem
                    label={safeGetMessage(locationFormBundle, 'city')}
                    $inline
                    $itemSize={CssSize.SHORT}
                    name={cityLocalNamePath}
                    rules={[{ required: allRequired, message: ' ' }]}
                    $removeSpaceBottom={!spaceBottom && !showType}
                    $readOnly={readOnly}
                    debounce
                    data-cy={createDataCyValue(
                      isPhysical
                        ? DataCyPrefix.PHYSICAL_CITY
                        : DataCyPrefix.MAILING_CITY,
                      dataCySuffix,
                    )}
                  >
                    <Input
                      disabled={disabled}
                      onBlur={() => formatString(form, cityFullNamePath)}
                    />
                  </FormItem>
                )}
                {showForm(stateCodeFullNamePath) && (
                  <Form.Item
                    shouldUpdate={shouldUpdate([countryCodeFullNamePath])}
                    noStyle
                  >
                    {() => (
                      <FormItem
                        label={safeGetMessage(
                          locationFormBundle,
                          'state_province',
                        )}
                        $itemSize={CssSize.SHORT}
                        name={stateCodeLocalNamePath}
                        rules={[{ required: allRequired, message: ' ' }]}
                        $removeSpaceBottom={!spaceBottom && !showType}
                        $readOnly={readOnly}
                        $inline
                        data-cy={createDataCyValue(
                          isPhysical
                            ? DataCyPrefix.PHYSICAL_STATE_CODE
                            : DataCyPrefix.MAILING_STATE_CODE,
                          dataCySuffix,
                        )}
                      >
                        {stateSelect({
                          disabled,
                          form,
                          countryCodeFullNamePath,
                          countryCodes,
                          notFoundContent: safeGetMessage(
                            sharedBundle,
                            'none_found',
                          ),
                        })}
                      </FormItem>
                    )}
                  </Form.Item>
                )}
              </StyledInputGroup>
              {showType && (
                <StyledInputGroup $removeSpaceBottom={!spaceBottom}>
                  <SimpleSelect
                    inline
                    form={form}
                    disabled={disabled}
                    readOnly={readOnly}
                    localNamePath={addressTypeCodeLocalNamePath}
                    fullNamePath={addressTypeCodeFullNamePath}
                    required={allRequired}
                    $itemSize={CssSize.SHORT}
                    label={safeGetMessage(locationFormBundle, 'address_type')}
                    selectOptions={Object.values(
                      omit(
                        UsCbp5106AddressTypeCode,
                        fullNamePath?.includes(physicalAddressNamePathSegment)
                          ? [
                              UsCbp5106AddressTypeCode.BUSINESS_SERVICE_CENTER,
                              UsCbp5106AddressTypeCode.POST_OFFICE_BOX,
                            ]
                          : [],
                      ),
                    )}
                    map={(typeCode: UsCbp5106AddressTypeCode) =>
                      capitalCase(typeCode)
                    }
                    dropdownMatchSelectWidth={false}
                    allowClear
                  />
                  <Form.Item
                    shouldUpdate={shouldUpdate([addressTypeCodeFullNamePath])}
                    noStyle
                  >
                    {() => {
                      const typeValue = form.getFieldValue(
                        addressTypeCodeFullNamePath,
                      );
                      return (
                        typeValue === UsCbp5106AddressTypeCode.OTHER && (
                          <FormItem
                            $inline
                            label={safeGetMessage(
                              locationFormBundle,
                              'explanation',
                            )}
                            $itemSize={CssSize.SHORT}
                            name={addressTypeExplanationLocalNamePath}
                            rules={[
                              {
                                required: allRequired,
                                max: 15,
                                message: ' ',
                              },
                            ]}
                            $readOnly={readOnly}
                            getValueFromEvent={maxLength(15)}
                            debounce
                          >
                            <Input
                              disabled={disabled}
                              onBlur={() =>
                                formatString(
                                  form,
                                  addressTypeExplanationFullNamePath,
                                )
                              }
                            />
                          </FormItem>
                        )
                      );
                    }}
                  </Form.Item>
                </StyledInputGroup>
              )}
            </StyledLocationFormDiv>
          );
        }}
      </Form.Item>
    )
  );
};

export default LocationForm;
