import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

export const DeloreanFeatures = {
  BASIC: 'BASIC',
  MAIN: 'MAIN',
  COMFORT: 'COMFORT',
};

const basicPhaseFeatureFlag = {
  feature: DeloreanFeatures.BASIC,
  enabled: true,
  stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA],
  startTime: {
    [Stage.ALPHA]: '2025-02-21',
    [Stage.BETA]: '2025-02-21',
    [Stage.GAMMA]: '2025-02-21',
  },
};

const mainPhaseFeatureFlag = {
  feature: DeloreanFeatures.MAIN,
  enabled: false,
  stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA],
  startTime: undefined,
};

const comfortPhaseFeatureFlag = {
  feature: DeloreanFeatures.COMFORT,
  enabled: false,
  stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA],
  startTime: undefined,
};

export const DELOREAN_FEATURE_FLAG_SERVICE = new FeatureFlagService([
  basicPhaseFeatureFlag,
  mainPhaseFeatureFlag,
  comfortPhaseFeatureFlag,
]);
