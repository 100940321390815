import { type Options, pascalCase as originalPascalCase } from 'change-case';
import type { RecordTypeStrings, Pascalize } from '.';

const pascalCase = <
  T extends RecordTypeStrings | (string & NonNullable<unknown>), // Lets us keep autocomplete while still accepting all strings
>(
  input: T,
  options?: Options,
): T extends RecordTypeStrings ? Pascalize<T> : string =>
  originalPascalCase(input, options) as T extends RecordTypeStrings
    ? Pascalize<T>
    : string;

export { pascalCase };
