import { type Options, camelCase as originalCamelCase } from 'change-case';
import type { RecordTypeStrings, Camelize } from '.';

const camelCase = <
  T extends RecordTypeStrings | (string & NonNullable<unknown>), // Lets us keep autocomplete while still accepting all strings
>(
  input: T,
  options?: Options,
): T extends RecordTypeStrings ? Camelize<T> : string =>
  originalCamelCase(input, options) as T extends RecordTypeStrings
    ? Camelize<T>
    : string;

export { camelCase };
