import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

export const deliveryOrderAutomationFeature =
  'DELIVERY_ORDER_AUTOMATION_FEATURE';

const DELIVERY_ORDER_AUTOMATION_FEATURE = new FeatureFlagService([
  {
    feature: deliveryOrderAutomationFeature,
    enabled: true,
    stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  },
]);

export { DELIVERY_ORDER_AUTOMATION_FEATURE };
