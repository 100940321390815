import moment from 'moment';
// specifically US CBP holidays
// Needs to be updating at least annually
// https://content.govdelivery.com/accounts/USDHSCBP/bulletins/2735246
// https://www.opm.gov/policy-data-oversight/pay-leave/federal-holidays#url=Overview

// used in the print date calculation of the entries
export const usHolidays = [
  '2018-01-01',
  '2018-01-15',
  '2018-02-19',
  '2018-05-28',
  '2018-07-04',
  '2018-09-03',
  '2018-10-08',
  '2018-11-12',
  '2018-11-22',
  '2018-12-25',
  '2019-01-01',
  '2019-01-21',
  '2019-02-18',
  '2019-05-27',
  '2019-07-04',
  '2019-09-02',
  '2019-10-14',
  '2019-11-11',
  '2019-11-28',
  '2019-12-25',
  '2020-01-01',
  '2020-01-20',
  '2020-02-17',
  '2020-05-25',
  '2020-07-03',
  '2020-09-07',
  '2020-10-12',
  '2020-11-11',
  '2020-11-26',
  '2020-12-24',
  '2020-12-25',
  '2021-01-01',
  '2021-01-18',
  '2021-02-15',
  '2021-05-31',
  '2021-07-04',
  '2021-07-05',
  '2021-09-06',
  '2021-10-11',
  '2021-11-11',
  '2021-11-25',
  '2021-12-24',
  '2021-12-25',
  '2021-12-31',
  '2022-01-17',
  '2022-02-21',
  '2022-05-30',
  '2022-06-20',
  '2022-07-04',
  '2022-09-05',
  '2022-10-10',
  '2022-11-11',
  '2022-11-24',
  '2022-12-26',
  // https://app.asana.com/0/0/1203589269608051/f
  '2023-01-02', // Monday, January 2       New Year’s Day
  '2023-01-16', // Monday, January 16      Birthday of Martin Luther King, Jr.
  '2023-02-20', // Monday, February 20     Washington’s Birthday
  '2023-05-29', // Monday, May 29          Memorial Day
  '2023-06-19', // Monday, June 19         Juneteenth National Independence Day
  '2023-07-04', // Tuesday, July 4         Independence Day
  '2023-09-04', // Monday, September 4     Labor Day
  '2023-10-09', // Monday, October 9       Columbus Day
  '2023-11-10', // Friday, November 10     Veterans Day
  '2023-11-23', // Thursday, November 23   Thanksgiving Day
  '2023-12-25', // Monday, December 25     Christmas Day
  // https://www.opm.gov/policy-data-oversight/pay-leave/federal-holidays/#url=2024
  '2024-01-01',
  '2024-01-15',
  '2024-02-19',
  '2024-05-27',
  '2024-06-19',
  '2024-07-04',
  '2024-09-02',
  '2024-10-14',
  '2024-11-11',
  '2024-11-28',
  '2024-12-24',
  // https://www.whitehouse.gov/briefing-room/presidential-actions/2024/12/19/executive-order-on-providing-for-the-closing-of-executive-departments-and-agencies-of-the-federal-government-on-december-24-2024/
  '2024-12-25',
  // https://www.opm.gov/policy-data-oversight/pay-leave/federal-holidays/#url=2025
  '2025-01-01',
  '2025-01-09',
  // https://www.whitehouse.gov/briefing-room/presidential-actions/2024/12/30/executive-order-providing-for-the-closing-of-executive-departments-and-agencies-of-the-federal-government-on-january-9-2025/
  '2025-01-20',
  '2025-02-17',
  '2025-05-26',
  '2025-06-19',
  '2025-07-04',
  '2025-09-01',
  '2025-10-13',
  '2025-11-11',
  '2025-11-27',
  '2025-12-24',
  '2025-12-25',
  // https://www.opm.gov/policy-data-oversight/pay-leave/federal-holidays/#url=2026
  '2026-01-01',
  '2026-01-19',
  '2026-02-16',
  '2026-05-25',
  '2026-06-19',
  '2026-07-03',
  '2026-09-07',
  '2026-10-12',
  '2026-11-11',
  '2026-11-26',
  '2026-12-25',
  // https://www.opm.gov/policy-data-oversight/pay-leave/federal-holidays/#url=2027
  '2027-01-01',
  '2027-01-18',
  '2027-02-15',
  '2027-05-31',
  '2027-06-18',
  '2027-07-05',
  '2027-09-06',
  '2027-10-11',
  '2027-11-11',
  '2027-11-25',
  '2027-12-24',
  // https://www.opm.gov/policy-data-oversight/pay-leave/federal-holidays/#url=2028
  '2027-12-31', // Replacing 2028 New Year - if a holiday falls on a Saturday, for most Federal employees, the preceding Friday will be treated as a holiday for pay and leave purposes.
  '2028-01-17',
  '2028-02-21',
  '2028-05-29',
  '2028-06-19',
  '2028-07-04',
  '2028-09-04',
  '2028-10-09',
  '2028-11-10',
  '2028-11-23',
  '2028-12-25',
  // https://www.opm.gov/policy-data-oversight/pay-leave/federal-holidays/#url=2029
  '2029-01-01',
  '2029-01-15',
  '2029-02-19',
  '2029-05-28',
  '2029-06-19',
  '2029-07-04',
  '2029-09-03',
  '2029-10-08',
  '2029-11-12',
  '2029-11-22',
  '2029-12-25',
  // https://www.opm.gov/policy-data-oversight/pay-leave/federal-holidays/#url=2030
  '2030-01-01',
  '2030-01-21',
  '2030-02-18',
  '2030-05-27',
  '2030-06-19',
  '2030-07-04',
  '2030-09-02',
  '2030-10-14',
  '2030-11-11',
  '2030-11-28',
  '2030-12-25',

  // Past dates are maintained in this list to aid in potential debugging.
  // Amazon has API to retrieve these values, but we've elected to maintain a static list to avoid any latency issues and because maintaining this list is far less lift than connecting to that API.
];

export const checkUsHolidays = (date: moment.Moment | Date) => {
  if (!date) throw new Error('Missing date in checkUsHoliday');
  const str = date.toISOString().substr(0, 10);
  return usHolidays.includes(str);
};
