import { AnyObject } from '@xbcb/shared-types';
import { getHtsDataFromHierarchy } from './getHtsDataFromHierarchy';
import { DateTime, Interval } from 'luxon';

export const validateEffectiveHTS = (
  htsNumber: string,
  htsEffectiveDate = DateTime.now(),
  hierarchyData: AnyObject = {},
): string | undefined => {
  const htsObj = getHtsDataFromHierarchy(htsNumber, hierarchyData);
  if (!htsObj?.id) {
    throw new Error('Invalid HTS number');
  }
  const { startDate, endDate } = htsObj;

  const isValidHts = isHtsValidForEffectiveDate(htsObj, htsEffectiveDate);
  if (!isValidHts) {
    const errorMessage = endDate
      ? `Error: ${htsNumber} invalid for effective date ${htsEffectiveDate} for start date ${startDate}, end date ${endDate}`
      : `Error: ${htsNumber} invalid for effective date ${htsEffectiveDate} for start date ${startDate}`;
    throw new Error('HTS number not valid for effective date');
    return errorMessage;
  }
  return;
};

export const isHtsValidForEffectiveDate = (
  htsObj: AnyObject,
  htsEffectiveDate = DateTime.now(),
): boolean => {
  const { startDate, endDate } = htsObj;
  if (startDate && endDate) {
    return Interval.fromDateTimes(startDate, endDate).contains(
      htsEffectiveDate,
    );
  } else if (startDate) {
    return htsEffectiveDate >= startDate;
  }
  return false;
};
