import { EsDataType } from '../index';
import { shipperId, shipperName } from './party';
import { createTableConfig } from './createTableConfig';

const columns = {
  shipperName,
  shipperId,
  clientIdentifier: {
    default: true,
    name: 'Item Number',
    type: EsDataType.TEXT,
  },
  productName: {
    default: true,
    name: 'Name',
    type: EsDataType.TEXT,
  },
  cbpDescription: {
    name: 'CBP Description',
    type: EsDataType.TEXT,
  },
  supplierName: {
    name: 'Supplier',
    type: EsDataType.KEYWORD,
  },
  supplierId: {
    hidden: true,
    name: 'Supplier ID',
    type: EsDataType.KEYWORD,
  },
  countryName: {
    default: true,
    name: 'Country',
    type: EsDataType.KEYWORD,
  },
  htsNumber: {
    default: true,
    name: 'HTS #',
    type: EsDataType.KEYWORD,
  },
  // TODO ADD BACK BUT DISCUSS FORMAT/SCHEMA
  // rateString: {
  //   default: true,
  //   name: 'Duty',
  //   type: EsDataType.KEYWORD,
  // },
  unitValue: {
    name: 'Unit Value',
    type: EsDataType.FLOAT,
  },
  iorName: {
    name: 'IOR name',
    type: EsDataType.TEXT,
  },
  asin: {
    name: 'ASIN',
    type: EsDataType.TEXT,
  },
  status: {
    name: 'Status',
    type: EsDataType.TEXT,
  },
};

export const product = createTableConfig({
  columns,
});
