import { getRecordType } from '@xbcb/core';
import { RecordType } from '@xbcb/shared-types';

export const customsEntryAndDeclarationRecordTypes: RecordType[] = [
  RecordType.GB_CUSTOMS_ENTRY,
  RecordType.DE_CUSTOMS_ENTRY,
  RecordType.NL_CUSTOMS_ENTRY,
  RecordType.FR_CUSTOMS_ENTRY,
  RecordType.CUSTOMS_DECLARATION,
];

export const isCustomsEntryOrDeclaration = (id?: string) =>
  !!id &&
  customsEntryAndDeclarationRecordTypes.includes(
    getRecordType(id) as RecordType,
  );
