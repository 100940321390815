import { AccountType, RecordType, Role } from '@xbcb/shared-types';
import { constantCase } from '@xbcb/js-utils';

// return accountType if user record type id is given.
// anything else returns undefined.
export const getUserAccountType = (id?: string, paamPartyRole?: Role) => {
  if (!id) {
    switch (paamPartyRole) {
      case Role.SHIPPER:
        return AccountType.SHIPPER;
      case Role.FORWARDER:
        return AccountType.FORWARDER;
      default:
        return;
    }
  }
  const possibleUser = constantCase(id.split('_')[0]);
  if (RecordType[possibleUser as keyof typeof RecordType]) {
    const map: { [key: string]: AccountType } = {
      [RecordType.FORWARDER_USER]: AccountType.FORWARDER,
      [RecordType.SHIPPER_USER]: AccountType.SHIPPER,
      [RecordType.OPERATOR_USER]: AccountType.OPERATOR,
    };
    return map[possibleUser] as AccountType;
  }
  return;
};
