import React, { useCallback } from 'react';
import { Input, Form, Divider } from 'antd';
import { FormInstance, FormListFieldData } from 'antd/lib/form';
import { FormItem } from '@xbcb/form-item-components';
import { formatString, safeGetMessage } from '@xbcb/ui-utils';
import { NamePath } from '@xbcb/ui-types';
import { useBundle } from '@amzn/react-arb-tools';
import { AddressV2 } from '@xbcb/api-gateway-client';
import { primaryTheme } from '@xbcb/ui-styles';

interface AddressFormProps {
  form: FormInstance;
  fullNamePath: NamePath;
  localNamePath?: NamePath;
  readOnly: boolean;
  disabled: boolean;
  dataCySuffix?: string;
}

const ADDRESS_FIELDS = {
  TYPE: 'type',
  LINE: 'line',
  CITY_NAME: 'cityName',
  COUNTRY: 'country',
  COUNTRY_SUBDIVISION: 'countrySubDivision',
  COUNTRY_SUBDIVISION_NAME: 'countrySubDivisionName',
  POST_CODE: 'postCode',
  STREET_NAME: 'streetName',
  HOUSE_NUMBER: 'houseNumber',
  HOUSE_NUMBER_ADDITION: 'houseNumberAddition',
  POST_OFFICE_BOX: 'postOfficeBox',
} as const;

const AddressForm: React.FC<AddressFormProps> = ({
  form,
  fullNamePath,
  localNamePath,
  readOnly,
  disabled,
  dataCySuffix,
}) => {
  const [AddressFormBundle] = useBundle(
    'components.formComponents.AddressForm',
  );
  const addressList: AddressV2[] = Form.useWatch(fullNamePath, form);

  const renderFormItem = useCallback(
    (
      itemSize: string,
      fieldName: string,
      label: string,
      fieldKey: number,
      onBlur?: () => void,
    ) => (
      <FormItem
        $itemSize={itemSize}
        $inline
        name={[fieldKey, fieldName]}
        label={label}
        $readOnly={readOnly}
        key={`${fieldKey}-${fieldName}`}
        debounce
      >
        <Input
          disabled={disabled}
          onBlur={onBlur}
          data-cy={dataCySuffix ? `${dataCySuffix}-${fieldName}` : undefined}
        />
      </FormItem>
    ),
    [disabled, readOnly, dataCySuffix],
  );

  const renderAddressDetails = useCallback(
    (field: FormListFieldData) => {
      const hasAddressLine = form.getFieldValue([
        ...fullNamePath,
        field.name,
        ADDRESS_FIELDS.LINE,
      ]);

      return (
        <div key={field.key} className="address-field-container">
          {renderFormItem(
            primaryTheme.size.medium,
            ADDRESS_FIELDS.TYPE,
            safeGetMessage(AddressFormBundle, 'type'),
            field.name,
          )}
          {
            // Adddress Line is not to be used in combination with
            // Street name, House number, House number addition and P.O. Box
          }
          {hasAddressLine ? (
            renderFormItem(
              primaryTheme.size.medium_large,
              ADDRESS_FIELDS.LINE,
              safeGetMessage(AddressFormBundle, 'address_line'),
              field.name,
              () => formatString(form, [field.name, ADDRESS_FIELDS.LINE]),
            )
          ) : (
            <>
              {renderFormItem(
                primaryTheme.size.medium_large,
                ADDRESS_FIELDS.STREET_NAME,
                safeGetMessage(AddressFormBundle, 'street_name'),
                field.name,
              )}
              {renderFormItem(
                primaryTheme.size.short,
                ADDRESS_FIELDS.HOUSE_NUMBER,
                safeGetMessage(AddressFormBundle, 'house_number'),
                field.name,
              )}
              {renderFormItem(
                primaryTheme.size.short,
                ADDRESS_FIELDS.HOUSE_NUMBER_ADDITION,
                safeGetMessage(AddressFormBundle, 'house_number_addition'),
                field.name,
              )}
              {renderFormItem(
                primaryTheme.size.short,
                ADDRESS_FIELDS.POST_OFFICE_BOX,
                safeGetMessage(AddressFormBundle, 'post_office_box'),
                field.name,
              )}
            </>
          )}

          {renderFormItem(
            primaryTheme.size.short,
            ADDRESS_FIELDS.CITY_NAME,
            safeGetMessage(AddressFormBundle, 'city_name'),
            field.name,
          )}
          {renderFormItem(
            primaryTheme.size.short,
            ADDRESS_FIELDS.COUNTRY_SUBDIVISION,
            safeGetMessage(AddressFormBundle, 'country_subdivision'),
            field.name,
          )}
          {renderFormItem(
            primaryTheme.size.short,
            ADDRESS_FIELDS.COUNTRY_SUBDIVISION_NAME,
            safeGetMessage(AddressFormBundle, 'country_subdivision_name'),
            field.name,
          )}
          {renderFormItem(
            primaryTheme.size.short,
            ADDRESS_FIELDS.COUNTRY,
            safeGetMessage(AddressFormBundle, 'country'),
            field.name,
          )}
          {renderFormItem(
            primaryTheme.size.short,
            ADDRESS_FIELDS.POST_CODE,
            safeGetMessage(AddressFormBundle, 'post_code'),
            field.name,
          )}
        </div>
      );
    },
    [AddressFormBundle, form, fullNamePath, renderFormItem],
  );

  return (
    <Form.List initialValue={addressList} name="addressList">
      {(fields) => (
        <div className="address-list-container">
          {fields.map((field, index) => {
            const lastElement = Boolean(fields.length - 1 === index);
            return (
              <div key={field.key} className="address-item">
                {renderAddressDetails(field)} {!lastElement && <Divider />}
              </div>
            );
          })}
        </div>
      )}
    </Form.List>
  );
};

export default AddressForm;
