import React from 'react';
import { Divider, Form, Input, Space } from 'antd';
import { FormInstance } from 'antd/lib/form';
import {
  INLTSwitch as Switch,
  DUNSNumber,
  Email,
  PhoneNumber,
  GlobalName,
  Checkbox,
  FormItem,
  Structure,
} from '@xbcb/form-item-components';
import LocationForm from '../LocationForm';
import PointOfContact from '../PointOfContact';
import UsCbpInfoCard, { UsCbpInfoCardProps } from '../UsCbpInfoCard';
import EuIorInfoCard from '../EuIorInfoCard';
import { SingleFormCard, PageContent } from '@xbcb/display-components';
import {
  BillingPartyAccountStatus,
  RecordType,
  PartyRecordType,
  AccountType,
} from '@xbcb/shared-types';
import {
  createDataCyValue,
  CssSize,
  DataCyPrefix,
  DataCySuffix,
} from '@xbcb/ui-types';
import { shouldUpdate, formatString, safeGetMessage } from '@xbcb/ui-utils';
import { StyledDunsWrapperDiv } from './styles';
import { useBundle } from '@amzn/react-arb-tools';

const euIorRecordTypes = [
  PartyRecordType.DE_IOR,
  PartyRecordType.GB_IOR,
  PartyRecordType.NL_IOR,
];

export type PartyFormBaseProps = {
  form: FormInstance;
  readOnly?: boolean;
  disabled?: boolean;
  accountType?: AccountType;
  recordType: PartyRecordType;
  additionalCompanyInfo?: React.ReactNode;
  showUsCbp5106AddressType?: boolean;
  addressRequired?: boolean;
  showWcoFormat?: boolean;
  contactInfoRequired?: boolean;
  showUnknownDuns?: boolean;
  enforceNamePattern?: boolean;
  usOnly?: boolean;
  hideDocs?: boolean;
  additionalRightContent?: React.ReactNode;
  // `Docs` should be the wrapped `DocsCore` component which needs to be
  // implemented in the respective UI that `PartyFormBase` is being used in,
  // thus it cannot be imported directly in this file, instead taken as a prop
  Docs?: React.ReactNode;
  // This is content that shows to the left, beneath the Company Info
  // SingleFormCard title
  companyInfoExtraContent?: React.ReactNode;
  $maxHeight?: string;
  ShipperSelect?: React.ReactNode;
  nameLabel?: React.ReactNode;
  hidePointOfContact?: boolean;
  businessStructureRequired?: boolean;
  companyTileTitle?: string;
  hideDba?: boolean;
  hideGlobalPhone?: boolean;
  hideGlobalEmail?: boolean;
  hideDuns?: boolean;
  hidePocTitle?: boolean;
  hideBusinessStructure?: boolean;
  // Sensitive info so we will enforce this prop to be required so we know
  // for sure whether we should show it or not
  showBillingDetails: boolean;
  showBusinessWebsite?: boolean;
  children?: React.ReactNode;
  // without Partial, Pick forces each property to be required, which we don't
  // want since these are optionally configurable
} & Partial<
  Pick<
    // The following props are configurable for `UsCbpInfoCard`.
    // Note: `hideHelpMeGetOne` defaults to true in `UsCbpInfoCard`
    UsCbpInfoCardProps,
    | 'hideHelpMeGetOne'
    | 'hidePreferredCbpEntryPaymentMethod'
    | 'iorNumberRequired'
    | 'verticalRequired'
    | 'hideVertical'
  >
>;

// This is the base PartyForm that can be used by any client. The client can
// create a wrapper component around the base that passes common props,
// particularly the `Docs` component that it prefers.
// TODO add ShipperSelect?
const PartyFormBase: React.FC<PartyFormBaseProps> = ({
  form,
  readOnly,
  disabled,
  accountType,
  additionalCompanyInfo,
  children,
  addressRequired,
  // TODO make this more general. This should easily allow for global expansion.
  // With each country having a slightly different address requirement that we
  // want to DISPLAY, but not STORE, next to the standard Address fields, then
  // really it should take in ReactNode. Consider using `additionalAddressInfo`
  // or something along those lines instead.
  showUsCbp5106AddressType,
  contactInfoRequired,
  showUnknownDuns,
  enforceNamePattern,
  usOnly,
  hideDocs,
  additionalRightContent,
  ShipperSelect,
  Docs,
  companyInfoExtraContent,
  $maxHeight,
  // Can be provided if a label other than `Name` is desired for the party's
  // name field
  nameLabel,
  hidePointOfContact,
  recordType,
  businessStructureRequired,
  hideHelpMeGetOne,
  hidePreferredCbpEntryPaymentMethod,
  iorNumberRequired,
  verticalRequired,
  hideVertical,
  companyTileTitle,
  hideDba,
  hideGlobalPhone,
  hideGlobalEmail,
  hideDuns,
  hidePocTitle,
  hideBusinessStructure,
  showBillingDetails,
  showBusinessWebsite,
  showWcoFormat = false,
}) => {
  const [partyFormBaseBundle] = useBundle(
    'components.formComponents.PartyFormBase',
  );
  const [sharedBundle] = useBundle('shared');
  const unknownDunsNamePath = ['unknownDuns'];
  const pointOfContactNamePath = ['pointOfContact'];
  const billingDetails = form.getFieldValue(['billingDetails']);
  const alternateNameNamePath = ['alternateName'];
  const businessStructureNamePath = ['businessStructure'];
  const iorNumberNamePath = ['iorNumber'];
  const businessWebsitePath = ['businessWebsite'];

  return (
    <PageContent
      rightContent={
        (additionalRightContent || (!hideDocs && Docs)) && (
          <>
            {additionalRightContent}
            {hideDocs ? undefined : Docs}
          </>
        )
      }
      $maxHeight={$maxHeight}
    >
      {ShipperSelect}
      <SingleFormCard
        title={
          companyTileTitle ||
          safeGetMessage(partyFormBaseBundle, 'company_info')
        }
        $noMarginBottom={!children}
        extraContent={companyInfoExtraContent}
      >
        {showBillingDetails && billingDetails && (
          <>
            <h4>{safeGetMessage(partyFormBaseBundle, 'account_status')}</h4>
            {!readOnly && (
              <Switch
                onWord={safeGetMessage(partyFormBaseBundle, 'active')}
                offWord={safeGetMessage(partyFormBaseBundle, 'suspended')}
                field={'accountStatus'}
                disabled={readOnly && disabled}
                onChange={(newStatus: boolean) => {
                  form.setFields([
                    {
                      name: ['billingDetails', 'status'],
                      value: newStatus
                        ? BillingPartyAccountStatus.ACTIVE
                        : BillingPartyAccountStatus.SUSPENDED,
                    },
                  ]);
                }}
              />
            )}
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.accountStatus !== curValues.accountStatus
              }
              noStyle
            >
              {() => {
                const accountStatus = form.getFieldValue([
                  'billingDetails',
                  'status',
                ]);
                return (
                  readOnly &&
                  (accountStatus === BillingPartyAccountStatus.ACTIVE ? (
                    <span style={{ color: 'green', marginLeft: '8px' }}>
                      {safeGetMessage(partyFormBaseBundle, 'active')}
                    </span>
                  ) : (
                    <span style={{ color: 'red', marginLeft: '8px' }}>
                      {safeGetMessage(partyFormBaseBundle, 'suspended')}
                    </span>
                  ))
                );
              }}
            </Form.Item>
            <Divider />
          </>
        )}
        <h4>{safeGetMessage(partyFormBaseBundle, 'contact_info')}</h4>
        <GlobalName
          form={form}
          disabled={disabled}
          readOnly={readOnly}
          label={nameLabel}
        />
        {!hideDba && (
          <>
            <Divider />
            <FormItem
              label={safeGetMessage(partyFormBaseBundle, 'alternate_name')}
              name={alternateNameNamePath}
              rules={[{ required: false, message: ' ' }]}
              $readOnly={readOnly}
              $itemSize={CssSize.MEDIUM}
              data-cy={createDataCyValue(
                DataCyPrefix.ALTERNATE_NAME,
                DataCySuffix.PARTY_CONTACT_INFO,
              )}
            >
              <Input
                disabled={disabled}
                onBlur={() => formatString(form, alternateNameNamePath)}
              />
            </FormItem>
          </>
        )}
        {showBusinessWebsite && (
          <>
            <FormItem
              label={safeGetMessage(partyFormBaseBundle, 'business_website')}
              name={businessWebsitePath}
              rules={[{ required: false, message: ' ' }]}
              $readOnly={readOnly}
              $itemSize={CssSize.MEDIUM}
              data-cy={createDataCyValue(
                DataCyPrefix.BUSINESS_WEBSITE,
                DataCySuffix.PARTY_CONTACT_INFO,
              )}
            >
              <Input
                disabled={disabled}
                onBlur={() => formatString(form, businessWebsitePath)}
              />
            </FormItem>
          </>
        )}
        <div>
          {!hideGlobalEmail && (
            <Email
              readOnly={readOnly}
              disabled={disabled}
              required={contactInfoRequired}
              localNamePath={['email']}
              $inline
              $spaceBottom={false}
              dataCySuffix={DataCySuffix.PARTY_CONTACT_INFO}
            />
          )}
          {!hideGlobalPhone && (
            <PhoneNumber
              readOnly={readOnly}
              disabled={disabled}
              form={form}
              localNamePath={['phone']}
              fullNamePath={['phone']}
              required={contactInfoRequired}
              $inline
              $spaceBottom={false}
              defaultCountryCode="1"
              allowClearCountryCode={false}
              dataCySuffix={DataCySuffix.PARTY_CONTACT_INFO}
            />
          )}
        </div>
        <Divider />
        {showWcoFormat ? (
          <Space direction="vertical">
            <h4>Addresses</h4>
            <LocationForm
              recordType={recordType}
              ior={false}
              readOnly={readOnly}
              disabled={true}
              form={form}
              // TODO spaceBottom is false in Shipper but true (undefined) in Trucker
              spaceBottom={false}
              allRequired
              fullNamePath={['addressList']}
              showWcoFormat={true}
              dataCySuffix={DataCySuffix.PARTY_CONTACT_INFO}
            />
          </Space>
        ) : (
          <>
            <h4>{safeGetMessage(partyFormBaseBundle, 'physical_address')}</h4>
            <LocationForm
              recordType={recordType}
              ior={false}
              readOnly={readOnly}
              disabled={disabled}
              form={form}
              // TODO spaceBottom is false in Shipper but true (undefined) in Trucker
              spaceBottom={false}
              allRequired
              fullNamePath={['addresses', 'physical']}
              showType={showUsCbp5106AddressType}
              usOnly={usOnly}
              dataCySuffix={DataCySuffix.PARTY_CONTACT_INFO}
            />
            <Divider />
            <h4>{safeGetMessage(partyFormBaseBundle, 'mailing_address')}</h4>
            {!readOnly && (
              <Switch
                onWord={safeGetMessage(sharedBundle, 'yes')}
                offWord={safeGetMessage(sharedBundle, 'no')}
                label={safeGetMessage(
                  partyFormBaseBundle,
                  'same_as_physical_address_question',
                )}
                field="sameMailingAddress"
                disabled={disabled}
                initialValue={true}
                onChange={(newStatus: boolean) => {
                  // If we toggle the switch to "Yes", reset the mailing address data
                  if (newStatus) {
                    form.setFields([
                      {
                        name: ['addresses', 'mailing'],
                        value: undefined,
                      },
                    ]);
                  }
                }}
                dataCySuffix={DataCySuffix.SAME_MAILING_ADDRESS}
              />
            )}
            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.sameMailingAddress !== curValues.sameMailingAddress
              }
              noStyle
            >
              {() => {
                const sameMailingAddress =
                  form.getFieldValue('sameMailingAddress');
                return !sameMailingAddress ? (
                  <LocationForm
                    recordType={recordType}
                    ior={false}
                    readOnly={readOnly}
                    disabled={disabled}
                    form={form}
                    // TODO spaceBottom is false in Shipper but true (undefined) in Trucker and Facility
                    spaceBottom={false}
                    fullNamePath={['addresses', 'mailing']}
                    allRequired={addressRequired}
                    showType={showUsCbp5106AddressType}
                    usOnly={usOnly}
                    dataCySuffix={DataCySuffix.PARTY_CONTACT_INFO}
                  />
                ) : (
                  readOnly && (
                    <span style={{ color: 'black', marginLeft: '8px' }}>
                      {safeGetMessage(
                        partyFormBaseBundle,
                        'same_as_physical_address',
                      )}
                    </span>
                  )
                );
              }}
            </Form.Item>
          </>
        )}
        {!hidePointOfContact && (
          <>
            <Divider />
            <Space direction="vertical">
              <h4>{safeGetMessage(partyFormBaseBundle, 'point_of_contact')}</h4>
              <PointOfContact
                readOnly={readOnly}
                disabled={disabled}
                form={form}
                enforceNamePattern={enforceNamePattern}
                fullNamePath={pointOfContactNamePath}
                localNamePath={pointOfContactNamePath}
                defaultCountryCode="1"
                allowClearCountryCode={false}
                hideTitle={hidePocTitle}
                dataCySuffix={DataCySuffix.PARTY_POINT_OF_CONTACT}
              />
            </Space>
          </>
        )}
        <Divider />
        <Space direction="vertical">
          <h4>
            {safeGetMessage(partyFormBaseBundle, 'additional_company_info')}
          </h4>
          <>
            {/* EuIorInfoCard should only display for Eu IOR Records */}
            {euIorRecordTypes.includes(recordType as PartyRecordType) && (
              <EuIorInfoCard
                recordType={recordType as PartyRecordType}
                form={form}
                disabled={disabled}
                readOnly={readOnly}
              />
            )}
            {!hideBusinessStructure && (
              <Structure
                form={form}
                disabled={disabled}
                readOnly={readOnly}
                localNamePath={businessStructureNamePath}
                onChange={() => {
                  form.setFields([
                    { name: iorNumberNamePath, value: undefined },
                  ]);
                }}
                required={businessStructureRequired}
                dataCySuffix={DataCySuffix.PARTY_ADDITIONAL_COMPANY_INFO}
              />
            )}
            <Form.Item
              shouldUpdate={shouldUpdate([unknownDunsNamePath])}
              noStyle
            >
              {() => {
                const unknownDunsNumber =
                  form.getFieldValue(unknownDunsNamePath);
                return (
                  !unknownDunsNumber &&
                  !hideDuns && (
                    <StyledDunsWrapperDiv>
                      <DUNSNumber
                        form={form}
                        readOnly={readOnly}
                        disabled={unknownDunsNumber || disabled}
                        $inline
                        dataCySuffix={
                          DataCySuffix.PARTY_ADDITIONAL_COMPANY_INFO
                        }
                      />
                    </StyledDunsWrapperDiv>
                  )
                );
              }}
            </Form.Item>
            {showUnknownDuns && (
              <Checkbox
                form={form}
                fullNamePath={unknownDunsNamePath}
                text={safeGetMessage(partyFormBaseBundle, 'unknown_duns')}
                readOnly={readOnly}
                disabled={disabled}
                onChange={() => form.setFieldsValue({ duns: undefined })}
                $inline
                $spaceTop
              />
            )}
            {/* UsCbpInfo should only display for US_IOR and US_CONSIGNEE */}
            {(recordType === RecordType.US_IOR ||
              recordType === RecordType.US_CONSIGNEE) && (
              <UsCbpInfoCard
                form={form}
                disabled={disabled}
                readOnly={readOnly}
                recordType={recordType}
                accountType={accountType}
                hideHelpMeGetOne={hideHelpMeGetOne}
                hidePreferredCbpEntryPaymentMethod={
                  hidePreferredCbpEntryPaymentMethod
                }
                iorNumberRequired={iorNumberRequired}
                verticalRequired={verticalRequired}
                hideVertical={hideVertical}
                businessStructureNamePath={businessStructureNamePath}
                iorNumberNamePath={iorNumberNamePath}
                dataCySuffix={DataCySuffix.PARTY_ADDITIONAL_COMPANY_INFO}
              />
            )}
          </>
          {additionalCompanyInfo}
        </Space>
      </SingleFormCard>
      {children}
    </PageContent>
  );
};

export default PartyFormBase;
