import React, { useState } from 'react';
import { Button, Form, message } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { ApolloQueryResult } from '@apollo/client';
import { omit } from 'lodash';
import { TemplateDocumentSignRequest, Tag } from '@xbcb/api-gateway-client';
import { SignBox, RenderedTemplate } from '@xbcb/display-components';
/** Importing from dist since we want to avoid importing Bindings (which uses
'fs' and is prohibited from the UI due to security concerns) */
import { getTemplateFields } from '@xbcb/doc-utils/dist/getTemplateFields';
import { DocumentSignatureMethod } from '@xbcb/document-types';
import { UsBusinessStructure } from '@xbcb/party-types';
import { AnyObject, TagKey, RecordType } from '@xbcb/shared-types';
import { Attributions } from '@xbcb/static-text-components';
import { NamePath, AdditionalFormError } from '@xbcb/ui-types';
import {
  showValidationErrors,
  shouldUpdate,
  useSearchDocuments,
  transformParty,
  validateCbp5106Party,
  validateForm,
  safeGetMessage,
} from '@xbcb/ui-utils';
import {
  StyledSignFormDiv,
  StyledBottomContentSignDiv,
  StyledErrorMessageDiv,
  StyledAttributionsWrapper,
} from './styles';
import { filterRequiredDocuments } from './utils';
import { useBundle } from '@amzn/react-arb-tools';

type TemplateBasedSignSubmissionContentProps = {
  form: FormInstance;
  templateDocumentSignRequest: TemplateDocumentSignRequest;
  updateTemplateDocumentSignRequest: (props: {
    variables: AnyObject;
  }) => Promise<any>;
  mutationLoading: boolean;
  usIorId?: string;
  attribution: string;
  refetchTemplateDocumentSignRequest: () => Promise<ApolloQueryResult<any>>;
  additionalTags?: Tag[];
  physicalAddressCountryCodePath: NamePath;
};

const TemplateBasedSignSubmissionContent: React.FC<
  TemplateBasedSignSubmissionContentProps
> = ({
  templateDocumentSignRequest,
  form,
  updateTemplateDocumentSignRequest,
  mutationLoading,
  usIorId,
  attribution,
  refetchTemplateDocumentSignRequest,
  additionalTags = [],
  physicalAddressCountryCodePath,
}) => {
  const [bundle] = useBundle(
    'components.formComponents.TemplateBasedSignSubmissionContent',
  );
  const signatureNamePath = ['signature'];
  const { id, operator, template } = templateDocumentSignRequest;
  const { id: operatorId } = operator || {};
  const { html, templateType } = template;
  const [signatureMethod, setSignatureMethod] = useState(
    DocumentSignatureMethod.TYPE,
  );
  // the typed signature if user signs using the type method
  const [typedSignature, setTypedSignature] = useState('');

  // Can't use `getFieldsValue` because it doesn't return hidden fields
  // we must ts-ignore as TS expects `getFieldValue` to have an argument
  // but without when it will return all fields, including hidden fields.
  // This is _very_ critical as `signature` is a hidden field
  // eslint-disable-next-line
  // @ts-ignore
  const getAllFieldValues = () => form.getFieldValue();

  // In order to readQuery below, we must use this query ourselves. So oddly
  // enough, even though we don't use any result from this hook, we still need
  // to call it. This must use the exact same filter logic (aka tagsToSearchBy)
  // that the Docs component uses. Otherwise when we read the cache below it
  // will not read correctly (aka notice a doc was uploaded).
  const tagsToSearchBy = [
    ...additionalTags,
    { key: TagKey.TEMPLATE_DOCUMENT_SIGN_REQUEST_ID, value: id },
  ].filter(
    ({ key }) =>
      key === TagKey.US_IOR_ACTIVATION_ID ||
      key === TagKey.TEMPLATE_DOCUMENT_SIGN_REQUEST_ID,
  );
  const { documents } = useSearchDocuments({
    searchDeletedDocuments: false,
    operatorId,
    tags: tagsToSearchBy,
  });

  const getMissingRequiredDocumentTags = () => {
    // verify document tags using the current documents in cache
    const businessStructure: UsBusinessStructure =
      form.getFieldValue('businessStructure');
    const isUsResident =
      form.getFieldValue(physicalAddressCountryCodePath) === 'US';

    return filterRequiredDocuments({
      businessStructure,
      isUsResident,
      documents,
      templateType,
    });
  };

  // handleSign function is triggered when the user enters their signature on the SignBox
  const handleSign = (imageData: any, method: any, typed: any) => {
    form.setFieldsValue({ signature: imageData });
    if (typed !== typedSignature) setTypedSignature(typed);
    if (method !== signatureMethod) setSignatureMethod(method);
  };

  const handleSubmit = async () => {
    // 1. validate the form fields
    try {
      const values = getAllFieldValues();
      const { additionalErrors } = validateCbp5106Party({
        input: values,
        recordType: RecordType.US_IOR,
        currentUser: {},
      });
      const validateParams: {
        form: FormInstance;
        additionalErrors?: AdditionalFormError[];
        validateFields?: NamePath[];
      } = { form };
      validateParams.additionalErrors = additionalErrors;
      const isValid = await validateForm(validateParams);
      if (!isValid) return;
    } catch (errorInfo: any) {
      const errorFields: { name: string[] }[] = errorInfo.errorFields || [];
      const messages = errorFields.map(({ name }) =>
        safeGetMessage(bundle, 'required_or_invalid', { doc: name.join(', ') }),
      );
      showValidationErrors([
        { title: safeGetMessage(bundle, 'invalid_fields'), messages: messages },
      ]);
      return;
    }

    // 2. validate the signature
    const finalSignature = form.getFieldValue(signatureNamePath);
    if (!finalSignature) {
      message.error(safeGetMessage(bundle, 'invalid_fields'));
      return;
    }

    // 3. validate that all required documents
    const missingRequiredDocumentTags = getMissingRequiredDocumentTags();
    if (missingRequiredDocumentTags?.size) {
      const errorMessage = (
        <StyledErrorMessageDiv>
          <p>{safeGetMessage(bundle, 'these_documents_need_to_be_uploaded')}</p>
          <ul>
            {[...missingRequiredDocumentTags].map((docTag) => (
              <li>{docTag}</li>
            ))}
          </ul>
        </StyledErrorMessageDiv>
      );
      message.error(errorMessage, 8);
      // Return, as we don't want to attempt to update the template DSR until
      // all required documents are uploaded and tagged
      return;
    }

    // 4. query the template DSR again to ensure we have the latest version
    //    (since PW may have updated the version)
    const { data: templateDsrQueryData } =
      await refetchTemplateDocumentSignRequest();
    const { version } = templateDsrQueryData?.templateDocumentSignRequest || {};

    const businessStructure: UsBusinessStructure =
      form.getFieldValue('businessStructure');

    // If we've made it here that means the fields were valid, the document has
    // been signed, all required documents have been uploaded, and we have the
    // latest version. Thus, we can proceed to updating the template DSR
    let values = getAllFieldValues();
    values = {
      ...values,
      businessStructure,
    };
    const {
      signature: signatureData,
      signerName,
      signerTitle,
      signerBirthDate,
    } = values;

    // All the UsIor fields (aka all fields other than signature fields)
    // should be sent as `additionalFields` which will then be used by PW to
    // update the IOR. We should make sure the data is schema-ready using the
    // default transformParty.toSchema transformer first
    const additionalFields = transformParty.toSchema({
      // The input here should be all the `values` minus the signing fields as
      // the rest represent the IOR fields
      input: omit(values, [
        'signerName',
        'signature',
        'signerTitle',
        'signerBirthDate',
      ]),
    });
    // Now, take care of any specific Import Sign use cases
    // officerPhone is unique to Import sign and we should check for it here
    // like we do other phones. Since we default the country code but the phone
    // number itself is required, we should delete the entire `officerPhone` if
    // a `number` was not provided
    if (!additionalFields.officerPhone?.number) {
      delete additionalFields.officerPhone;
    }
    await updateTemplateDocumentSignRequest({
      variables: {
        id,
        version,
        signerName,
        signerTitle,
        signerBirthDate,
        signature: {
          method: signatureMethod,
          typedSignature,
          data: signatureData,
        },
        additionalFields,
      },
    });
  };
  return (
    <>
      <StyledSignFormDiv>
        <Form.Item shouldUpdate noStyle>
          {() => {
            // this is used when attempting to render form values into template
            const templateFormData = getTemplateFields(getAllFieldValues());
            return <RenderedTemplate html={html} data={templateFormData} />;
          }}
        </Form.Item>
        <Form.Item
          shouldUpdate={shouldUpdate([
            ['signerTitle'],
            ['signerName'],
            signatureNamePath,
          ])}
          noStyle
        >
          {() => <SignBox submitSignature={handleSign} form={form} />}
        </Form.Item>
      </StyledSignFormDiv>
      <StyledBottomContentSignDiv>
        <Button
          type="primary"
          size="large"
          onClick={handleSubmit}
          disabled={mutationLoading}
        >
          {safeGetMessage(bundle, 'submit')}
        </Button>
        <StyledAttributionsWrapper>
          <Attributions attribution={attribution} />
        </StyledAttributionsWrapper>
      </StyledBottomContentSignDiv>
    </>
  );
};

export default TemplateBasedSignSubmissionContent;
