import { SortOrder, AccountType } from '@xbcb/shared-types';
import {
  invitingPartyId,
  subscriptionPlan,
  arCustomerId,
  arOnboardingCountry,
  globalLogisticsProfileId,
} from './party';
import { createPartyTable } from './createPartyTable';
import { EsDataType } from '../../enums';
import { ShipperStatus } from '@xbcb/party-types';

export const shipper = createPartyTable({
  columns: {
    forwarderName: {
      default: false,
      name: (accountType: AccountType) =>
        accountType === AccountType.FORWARDER ? 'Agent Name' : 'Forwarder Name',
      type: EsDataType.TEXT,
    },
    forwarderId: {
      name: 'Forwarder ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    customsBrokerId: {
      name: 'Broker ID',
      type: EsDataType.KEYWORD,
      hidden: true,
    },
    customsBrokerName: {
      name: 'Broker Name',
      type: EsDataType.TEXT,
      default: true,
    },
    friendlyId: {
      name: 'ID',
      type: EsDataType.KEYWORD,
    },
    status: {
      name: 'Status',
      type: EsDataType.KEYWORD,
      optionValues: Object.keys(ShipperStatus),
    },
    subscriptionPlan,
    invitingPartyId,
    arCustomerId,
    arOnboardingCountry,
    globalLogisticsProfileId,
  },
  tableOptions: {
    sortOrder: SortOrder.DESC,
  },
});
