import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const OperatorUserProductPermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.PRODUCT]: OperatorUserProductPermissionName,
  [ObjectType.BULK_PRODUCT_UPLOAD_TEMPLATE_DOCUMENT_GENERATION_REQUEST]:
    OperatorUserProductPermissionName,
  [ObjectType.IMPORT_PRODUCTS_FROM_BULK_UPLOAD_REQUEST]:
    OperatorUserProductPermissionName,
  [ObjectType.IMPORT_PRODUCTS_FROM_CUSTOMS_ENTRIES_REQUEST]:
    OperatorUserProductPermissionName,
};
