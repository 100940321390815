import { FeatureFlagService } from '../featureFlagService';
import { Stage } from '@xbcb/core';

export const PathfinderFeatures = {
  PHASE_1: 'PHASE_1',
  PHASE_2: 'PHASE_2',
  PHASE_3: 'PHASE_3',
};

const phase1FeatureFlag = {
  feature: PathfinderFeatures.PHASE_1,
  enabled: true,
  stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  operators: undefined,
};

const phase2FeatureFlag = {
  feature: PathfinderFeatures.PHASE_2,
  enabled: true,
  stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  operators: undefined,
};

// Disabling Phase 3 in prod due to this TT:: https://t.corp.amazon.com/V1664355490
const phase3FeatureFlag = {
  feature: PathfinderFeatures.PHASE_3,
  enabled: true,
  stages: [Stage.ALPHA, Stage.BETA, Stage.GAMMA, Stage.PROD],
  operators: undefined,
};

export const PATHFINDER_FEATURE_FLAG_SERVICE = new FeatureFlagService([
  phase1FeatureFlag,
  phase2FeatureFlag,
  phase3FeatureFlag,
]);
