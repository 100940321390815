import { SupportedCountryAbbreviation } from '@xbcb/shared-types';

const euCuDeCountryCodes: string[] = [SupportedCountryAbbreviation.NL];

export const isEuCuDe = (
  recordId: string | undefined,
  workOrderCountryCode: string | undefined,
) =>
  recordId && workOrderCountryCode
    ? recordId.startsWith('customsDeclaration') &&
      euCuDeCountryCodes.includes(workOrderCountryCode)
    : false;
