import document from './document';
import party from './party';
import product from './product';
import workOrder from './workOrder';
import invoice from './invoice';
import shipment from './shipment';
import client from './client';
import reportReconciliationRequest from './reportReconciliationRequest';
import company from './company';
import bulkUpload from './bulkUpload';
import escalationMessage from './escalationMessage';
import exchangeMessage from './exchangeMessage';
import tradeData from './tradeData';
import type { ObjectType } from '@xbcb/shared-types';

export * from './document';
export * from './party';
export * from './product';
export * from './workOrder';
export * from './invoice';
export * from './shipment';
export * from './client';
export * from './reportReconciliationRequest';
export * from './company';
export * from './bulkUpload';
export * from './escalationMessage';
export * from './exchangeMessage';
export * from './tradeData';

type OperatorUserPermissions = Partial<
  Record<ObjectType, Record<string, string>>
>;

export const operatorUserPermissions = {
  ...document,
  ...party,
  ...product,
  ...workOrder,
  ...invoice,
  ...shipment,
  ...client,
  ...reportReconciliationRequest,
  ...company,
  ...bulkUpload,
  ...escalationMessage,
  ...exchangeMessage,
  ...tradeData,
} as const satisfies OperatorUserPermissions;
