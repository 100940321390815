// TODO split this into ImportSignModalKey and NewAppUiModal key one we start implementing modals in ImportSign. For now, ModalKey is being used by both UIs
export enum ModalKey {
  ACCEPT_UNPLANNED_CHARGES_FOR_PAYABLE_INVOICE_CONFIRMATION = 'ACCEPT_UNPLANNED_CHARGES_FOR_PAYABLE_INVOICE_CONFIRMATION',
  APP_RECORD_CONFIRMATION = 'APP_RECORD_CONFIRMATION',
  APP_RECORD_MEMO = 'APP_RECORD_MEMO',
  APP_RECORD_SEND_ABI_MESSAGE = 'APP_RECORD_SEND_ABI_MESSAGE',
  ASSIGN_EMAIL_TO_WORK_ORDER = 'ASSIGN_EMAIL_TO_WORK_ORDER',
  ASSIGN_WORK_ORDER_TASK = 'ASSIGN_WORK_ORDER_TASK',
  ASSIGN_WORK_ORDER_TASK_TO_TEAM = 'ASSIGN_WORK_ORDER_TASK_TO_TEAM',
  CANCEL_ENTRY_WITH_CBP = 'CANCEL_ENTRY_WITH_CBP',
  CANCEL_SHIPMENT = 'CANCEL_SHIPMENT',
  CANCEL_US_IOR_CONTINUOUS_BOND_REQUEST_RENEWAL_CONFIRMATION = 'CANCEL_US_IOR_CONTINUOUS_BOND_REQUEST_RENEWAL_CONFIRMATION',
  CANCEL_WORK_ORDER = 'CANCEL_WORK_ORDER',
  CHANNEL_CONNECTION_STATUS = 'CHANNEL_CONNECTION_STATUS',
  CHARGE_CONTINUOUS_BOND_RENEWAL = 'CHARGE_CONTINUOUS_BOND_RENEWAL',
  CLONE_PRODUCT = 'CLONE_PRODUCT',
  COMPLETE_WORK_ORDER_TASK = 'COMPLETE_WORK_ORDER_TASK',
  CREATE_BULK_CHARGES_UPLOAD_REQUEST = 'CREATE_BULK_CHARGES_UPLOAD_REQUEST',
  CREATE_BULK_COMPLIANCE_UPLOAD_REQUEST = 'CREATE_BULK_COMPLIANCE_UPLOAD_REQUEST',
  CREATE_BULK_MILESTONE_UPLOAD_REQUEST = 'CREATE_BULK_MILESTONE_UPLOAD_REQUEST',
  CREATE_CREDIT_NOTE = 'CREATE_CREDIT_NOTE',
  CREATE_CUSTOM_DOMAIN = 'CREATE_CUSTOM_DOMAIN',
  CREATE_EXCEPTION_TASK = 'CREATE_EXCEPTION_TASK',
  CREATE_MAIL_FROM = 'CREATE_MAIL_FROM',
  CREATE_REPORT_RECONCILIATION_REQUEST = 'CREATE_REPORT_RECONCILIATION_REQUEST',
  CREATE_SHIPPER_USER = 'CREATE_SHIPPER_USER',
  CREATE_SHIPMENT = 'CREATE_SHIPMENT',
  CREATE_US_IN_BOND = 'CREATE_US_IN_BOND',
  CREATE_US_IOR_CONTINUOUS_BOND_REQUEST = 'CREATE_US_IOR_CONTINUOUS_BOND_REQUEST',
  CREATE_US_POST_SUMMARY_CORRECTION = 'CREATE_US_POST_SUMMARY_CORRECTION',
  CREATE_WORK_ORDER = 'CREATE_WORK_ORDER',
  DELETE_US_CONSUMPTION_ENTRY_RELEASE_CBP = 'DELETE_US_CONSUMPTION_ENTRY_RELEASE_CBP',
  DELETE_US_TYPE86_ENTRY_RELEASE_CBP = 'DELETE_US_TYPE86_ENTRY_RELEASE_CBP',
  EDIT_DOCUMENT = 'EDIT_DOCUMENT',
  ESCALATE_WORK_ORDER_TASK = 'ESCALATE_WORK_ORDER_TASK',
  GENERAL_CONFIRMATION = 'GENERAL_CONFIRMATION',
  IMPORT_INVOICES = 'IMPORT_INVOICES',
  INCLUDE_PRODUCT_LEVEL_DETAILS = 'INCLUDE_PRODUCT_LEVEL_DETAILS',
  INQUIRE_WORK_ORDER = 'INQUIRE_WORK_ORDER',
  INVOICE_CREATE = 'INVOICE_CREATE',
  INVOICE_LINE_COUNT = 'INVOICE_LINE_COUNT',
  INVOICE_VOID = 'INVOICE_VOID',
  LINK_FORWARDER = 'LINK_FORWARDER',
  MAP_SPREADSHEET = 'MAP_SPREADSHEET',
  MARK_WORK_ORDER_HOT = 'MARK_WORK_ORDER_HOT',
  MARK_WORK_ORDER_READY = 'MARK_WORK_ORDER_READY',
  MFA_PREFERENCES = 'MFA_PREFERENCES',
  ONBOARDING_MODAL = 'ONBOARDING_MODAL',
  PENDING_SUB_POAS = 'PENDING_SUB_POAS',
  PRICING_GRID = 'PRICING_GRID',
  QUERY_AMS = 'QUERY_AMS',
  REJECT_IOR_ACTIVATION = 'REJECT_IOR_ACTIVATION',
  REQUEST_CBP_NUMBER = 'REQUEST_CBP_NUMBER',
  RESOLUTION_WORK_ORDER_TASK = 'RESOLUTION_WORK_ORDER_TASK',
  SEND_DOCUMENT = 'SEND_DOCUMENT',
  SHIPPER_USER_ACCESS = 'SHIPPER_USER_ACCESS',
  SUBMIT_SOP = 'SUBMIT_SOP',
  SUBSCRIPTION_UPDATE_WARNING = 'SUBSCRIPION_UPDATE_WARNING',
  TAG_FORWARDER = 'TAG_FORWARDER',
  TEAM_DATA = 'TEAM_DATA',
  UPDATE_STATEMENT = 'UPDATE_STATEMENT',
  VALIDATE_US_ENTRY_SUMMARY = 'VALIDATE_US_ENTRY_SUMMARY',
}
