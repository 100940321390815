export enum ProductSecondaryIdentifierType {
  ASIN = 'ASIN',
}
export enum AdCvdCasePaymentMethod {
  BOND = 'BOND',
  CASH = 'CASH',
}
export enum ProductStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  INTERNAL = 'INTERNAL',
}
