import { ApolloError, useMutation } from '@apollo/client';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert, Form, Input, Modal, message } from 'antd';
import { constantCase, noCase } from 'change-case';
import { get, pick } from 'lodash';
import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { FormItem, TagSelect } from '@xbcb/form-item-components';
import { DocumentTag } from '@xbcb/document-types';
import { documentFragments } from '@xbcb/document-queries';
import { RecordType, Tag } from '@xbcb/shared-types';
import {
  SearchQuery,
  SearchType,
  updateOneMutation,
  createSearchQueryVariables,
} from '@xbcb/shared-queries';
import { mutateRecord, retryMutation } from '@xbcb/apollo-client';
import {
  reportError,
  shouldUpdate,
  documentInputFields,
  validateForm,
  useModal,
  safeGetMessage,
  formatDocumentTag,
} from '@xbcb/ui-utils';
import { StyledButton, StyledForm, StyledDiv } from './styles';
import { ModalKey } from '@xbcb/ui-types';
import { useBundle } from '@amzn/react-arb-tools';

interface EditDocModalProps {
  searchDeletedDocuments?: boolean;
  // The tags that should be used to search documents with
  searchQueryTags: Tag[];
  visible?: boolean;
  // Intentionally takes operatorId so that it is not tied to one way of
  // determining the operatorId. For example, in NewAppUi we often get it from
  // the currentUser but in ImportSign we get it from the queried document
  operatorId: string;
  availableDocumentTags?: DocumentTag[];
}

type ConflictingTagPair = [DocumentTag, DocumentTag];
interface ConflictResult {
  hasConflicts: boolean;
  conflicts: ConflictingTagPair[];
}

const checkTagConflicts = (
  tags: DocumentTag[],
  conflictingPairs: ConflictingTagPair[],
): ConflictResult => {
  const foundConflicts = conflictingPairs.filter(
    ([tag1, tag2]) => tags.includes(tag1) && tags.includes(tag2),
  );

  return {
    hasConflicts: foundConflicts.length > 0,
    conflicts: foundConflicts,
  };
};

const EditDocModal: React.FC<EditDocModalProps> = ({
  searchDeletedDocuments,
  searchQueryTags,
  visible,
  operatorId,
  availableDocumentTags = [],
}) => {
  const [sharedBundle] = useBundle('shared');
  const [editDocModalBundle] = useBundle(
    'components.modalComponents.EditDocModal',
  );
  const [documentTagBundle] = useBundle('types.document.enums.DocumentTag');

  const document = useSelector((state) =>
    get(state, `ui.modals.${ModalKey.EDIT_DOCUMENT}`, {}),
  );

  const { extension, documentTags = [], fileName, id, version } = document;

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { closeModal } = useModal(ModalKey.EDIT_DOCUMENT);

  const initialValues = { fileName, tags: documentTags };

  const searchQuery = SearchQuery({
    recordName: RecordType.DOCUMENT,
    fields: '...documentFields',
    fragments: documentFragments,
  });
  const searchQueryVariables = createSearchQueryVariables({
    deletedTimeExists: searchDeletedDocuments,
    operatorId,
    sortOptions: [
      {
        field: 'createdTime',
      },
    ],
    tags: searchQueryTags,
  });
  const optimisticUpdateParams = {
    gqlQueryString: searchQuery,
    updatedQueryType: 'search' as SearchType,
    variables: searchQueryVariables,
  };

  const [updateDocument] = useMutation(
    updateOneMutation({
      recordName: RecordType.DOCUMENT,
      fields: '...documentFields',
      fragments: documentFragments,
    }),
    {
      onError: async (error: ApolloError) => {
        try {
          const inputTags = getInputTags();
          await retryMutation({
            fields: '...documentFields',
            fragments: documentFragments,
            id,
            mutationType: 'update',
            mutation: updateDocument,
            mutationVariables: getMutationVariables(inputTags),
            recordType: RecordType.DOCUMENT,
            optimisticUpdateParams,
          });
        } catch (e) {
          message.error(
            safeGetMessage(sharedBundle, 'general_error_message'),
            5.0,
          );
          setLoading(false);
          closeModal();
        }
      },
    },
  );

  const getInputTags = () => {
    const tags = form.getFieldValue('tags');
    return tags.map((tag: string) => constantCase(tag));
  };

  const getMutationVariables = (inputTags: string[]) => {
    const fileName = form.getFieldValue('fileName');
    return {
      id,
      version,
      input: {
        ...pick(document, documentInputFields),
        documentTags: inputTags,
        fileName,
      },
    };
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await validateForm({ form });
      const inputTags = getInputTags();
      const result = await mutateRecord({
        mutationType: 'update',
        mutation: updateDocument,
        mutationVariables: getMutationVariables(inputTags),
        recordType: RecordType.DOCUMENT,
        optimisticUpdateParams,
      });
      if (result) {
        message.success(
          safeGetMessage(editDocModalBundle, 'tag_updated', {
            fileName: `${fileName}.${extension.toLowerCase()}`,
          }),
          5.0,
        );
        setLoading(false);
        closeModal();
      }
    } catch (e) {
      reportError(e);
      message.error(safeGetMessage(sharedBundle, 'general_error_message'), 5.0);
      setLoading(false);
      closeModal();
    }
  };

  const handleClose = () => {
    closeModal();
    form.resetFields();
  };

  const addonAfter = extension ? { addonAfter: `.${noCase(extension)}` } : {};

  const formatTag = useCallback(
    (tagValue: DocumentTag) =>
      safeGetMessage(
        documentTagBundle,
        tagValue.toLowerCase(),
        undefined,
        formatDocumentTag(tagValue),
      ),
    [documentTagBundle],
  );

  return (
    <Modal
      destroyOnClose={true}
      maskClosable={false}
      visible={visible}
      width={400}
      closable={true}
      footer={null}
      onCancel={handleClose}
    >
      <StyledForm form={form} initialValues={initialValues}>
        <FormItem shouldUpdate={shouldUpdate([['tags']])}>
          {() => {
            const tags = form.getFieldValue('tags');
            const arrivalNoticeAlert = tags.includes(
              DocumentTag.ARRIVAL_NOTICE,
            );
            const confidentialAlert = tags.includes(DocumentTag.CONFIDENTIAL);
            const { hasConflicts: containsConflictingTags, conflicts } =
              checkTagConflicts(tags, [
                [DocumentTag.CUSTOMS_ENTRY, DocumentTag.CUSTOMS_ENTRY_DRAFT],
                [
                  DocumentTag.CUSTOMS_DECLARATION,
                  DocumentTag.DRAFT_CUSTOMS_DECLARATION,
                ],
              ]);
            return (
              <>
                <StyledDiv>
                  <StyledButton
                    onClick={handleSubmit}
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    size="large"
                    disabled={containsConflictingTags}
                  >
                    <SaveOutlined /> {safeGetMessage(sharedBundle, 'save')}
                  </StyledButton>
                </StyledDiv>
                <FormItem
                  label={safeGetMessage(editDocModalBundle, 'file_name')}
                  name="fileName"
                  rules={[{ required: true }]}
                >
                  <Input disabled={loading} {...addonAfter} />
                </FormItem>
                <TagSelect
                  tags={availableDocumentTags}
                  label={safeGetMessage(editDocModalBundle, 'tags')}
                  formatTag={formatTag}
                  form={form}
                  disabled={loading}
                  maxTagCount={1}
                />
                {containsConflictingTags && (
                  <Alert
                    message={safeGetMessage(
                      editDocModalBundle,
                      // TODO custom message using the conflicts produced by checkTagConflicts
                      'contain_both_customs_entry_and_draft',
                    )}
                    type="info"
                  />
                )}
                {arrivalNoticeAlert && (
                  <Alert
                    message={safeGetMessage(
                      editDocModalBundle,
                      'arrival_notice_access_limit',
                    )}
                    type="info"
                  />
                )}
                {confidentialAlert && (
                  <Alert
                    message={safeGetMessage(
                      editDocModalBundle,
                      'confidential_docs_access_limit',
                    )}
                    type="info"
                  />
                )}
              </>
            );
          }}
        </FormItem>
      </StyledForm>
    </Modal>
  );
};

export default EditDocModal;
