import { calculateDynamoDBItemSize } from './dynamoDBSizeCalculator';
import log from '@xbcb/log';
import { RecordType, Tag, TagKey } from '@xbcb/shared-types';

export const MAX_ALLOWED_KILOBYTES = 370; // With some buffer over DDB Item size limitation of 400KB

export type RecordSizeThresholdConfig = {
  itemFieldPath: string[];
  maxItems: number;
};

export const recordSizeThresholdConfigMap: Partial<
  Record<RecordType, RecordSizeThresholdConfig[]>
> = {
  [RecordType.SHIPMENT]: [
    {
      itemFieldPath: ['legs', 'commercialInvoices', 'complianceDetails'],
      maxItems: 120,
    },
  ],
  [RecordType.US_CONSUMPTION_ENTRY]: [
    { itemFieldPath: ['invoices', 'products'], maxItems: 120 },
  ],
  [RecordType.US_CBP_7501_DOCUMENT_GENERATION_REQUEST]: [
    {
      itemFieldPath: ['invoices', 'lineItems'],
      maxItems: 120,
    },
  ],
};

/**
 * A util to identify if an object is larger than MAX_ALLOWED_KILOBYTES
 * @param {unknown} obj the object to check the size of
 * @param {RecordSizeThresholdConfig[]} recordSizeThresholdConfigs
 * @return {boolean}
 */
export const isObjectTooLarge = (
  obj: any,
  recordSizeThresholdConfigs?: RecordSizeThresholdConfig[],
): boolean => {
  try {
    if (!obj) return false;

    // Check if large entry is enabled via tags
    if (isLargeEntryEnabledViaTag(obj)) {
      log.info('Large entry enabled via tag');
      return true;
    }

    return (
      isSizeExceeded(obj) ||
      isItemCountExceeded(obj, recordSizeThresholdConfigs)
    );
  } catch (error) {
    throw new Error(`Unable to calculate item size, error : ${error}`);
  }
};

/**
 * Check if large entry is enabled via tags
 * @param {any} obj the object to check for tags
 * @return {boolean}
 */
const isLargeEntryEnabledViaTag = (obj: any): boolean => {
  if (obj?.tags && Array.isArray(obj.tags)) {
    return obj.tags.some(
      (tag: Tag) =>
        tag.key === TagKey.LARGE_ENTRY_ENABLED &&
        tag.value.toLowerCase() === 'true',
    );
  }
  return false;
};

const isSizeExceeded = (obj: any): boolean => {
  const sizeInKb = calculateDynamoDBItemSize(obj) / 1024;
  log.info(`Object size calculated as: ${sizeInKb} KB`);
  return sizeInKb >= MAX_ALLOWED_KILOBYTES;
};

const isItemCountExceeded = (
  obj: any,
  recordSizeThresholdConfigs?: RecordSizeThresholdConfig[],
): boolean => {
  if (!recordSizeThresholdConfigs) return false;
  return recordSizeThresholdConfigs.some((config) => {
    const items = getNestedFields(obj, config.itemFieldPath);
    log.debug(`Nested fields data : ${JSON.stringify(items)}`);
    const itemCount = Array.isArray(items) ? items.length : 0;
    log.info(
      `Item count for path [${config.itemFieldPath.join(
        '.',
      )}] is: ${itemCount}`,
      {
        key: 'CheckLargePayloadItemCountExceeded',
      },
    );
    return itemCount >= config.maxItems;
  });
};

const getNestedFields = (source: any, path: string[]) => {
  if (!source || path.length === 0) return [];
  return path.reduce(
    (currentLevel, key) => {
      return currentLevel
        .flatMap((item) => (item && Array.isArray(item[key]) ? item[key] : []))
        .filter(Boolean);
    },
    [source],
  );
};
