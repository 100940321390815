/* eslint-disable @typescript-eslint/no-namespace */
import originalPluralize from 'pluralize';
import type { Pluralize, RecordTypeStrings, Pascalize } from '.';

// TODO add custom pluralize rules.
// // Example of new plural rule:
// pluralize.addPluralRule(/gex$/i, 'gexii')
// pluralize.plural('regex') //=> "regexii"

// // Example of new singular rule:
// pluralize.addSingularRule(/singles$/i, 'singular')
// pluralize.singular('singles') //=> "singular"

// // Example of new irregular rule, e.g. "I" -> "we":
// pluralize.addIrregularRule('irregular', 'regular')
// pluralize.plural('irregular') //=> "regular"

originalPluralize.addSingularRule('hts', 'singular');
originalPluralize.addIrregularRule('hts', 'htss');
originalPluralize.addOverride('usHts', 'usHtss');
originalPluralize.addOverride('UsHts', 'UsHtss');
originalPluralize.addSingularRule('usHts', 'singular');

function pluralize<
  T extends Pascalize<RecordTypeStrings> | (string & NonNullable<unknown>), // Lets us keep autocomplete while still accepting all strings
>(
  word: T,
  count?: number,
  inclusive?: boolean,
): T extends Pascalize<RecordTypeStrings> ? Pluralize<T> : string {
  return originalPluralize(
    word,
    count,
    inclusive,
  ) as T extends Pascalize<RecordTypeStrings> ? Pluralize<T> : string;
}

namespace pluralize {
  export function plural(word: string) {
    return originalPluralize.plural(word);
  }

  export function singular(word: string) {
    return originalPluralize.singular(word);
  }

  // I like how the original has misspelled parameters.
  export function addPluralRule(rule: string | RegExp, replacement: string) {
    return originalPluralize.addPluralRule(rule, replacement);
  }

  export function addSingularRule(rule: string | RegExp, replacement: string) {
    return originalPluralize.addSingularRule(rule, replacement);
  }

  export function addIrregularRule(single: string, plural: string) {
    return originalPluralize.addIrregularRule(single, plural);
  }

  export function addUncountableRule(word: string | RegExp) {
    return originalPluralize.addUncountableRule(word);
  }

  export function addOverride(token: string, plural: string) {
    return originalPluralize.addOverride(token, plural);
  }

  export function isPlural(word: string) {
    return originalPluralize.isPlural(word);
  }

  export function isSingular(word: string) {
    return originalPluralize.isSingular(word);
  }
}

export { pluralize };
