import { CrudPermissionName } from '../base';
import { ObjectType } from '@xbcb/shared-types';

export const ShipperUserProductPermissionName = {
  ...CrudPermissionName,
};

export default {
  [ObjectType.PRODUCT]: ShipperUserProductPermissionName,
  [ObjectType.BULK_PRODUCT_UPLOAD_TEMPLATE_DOCUMENT_GENERATION_REQUEST]:
    ShipperUserProductPermissionName,
  [ObjectType.IMPORT_PRODUCTS_FROM_BULK_UPLOAD_REQUEST]:
    ShipperUserProductPermissionName,
  [ObjectType.IMPORT_PRODUCTS_FROM_CUSTOMS_ENTRIES_REQUEST]:
    ShipperUserProductPermissionName,
};
